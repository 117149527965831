<template>
  <PageContainer :title="pageTitle" :back="actions.back">
    <template v-slot:content>
      <div class="segment" v-if="loading">
        <div class="segment-content">
          <SkeletonDefault />
        </div>
      </div>
      <div v-if="!loading">
        <div
          class="page-segment padding-bottom-zero padding-right-zero padding-left-zero"
        >
          <div class="page-segment-content">
            <div
              class="heading-mate-60 padding-top padding-bottom padding-left padding-right"
              v-html="packageDescription"
            ></div>

            <!-- Slider Nav -->
            <SliderNav :options="sliderOptions" :route="$route" />
          </div>
        </div>

        <!-- available specialists -->
        <div class="page-segment margin-top">
          <div class="page-segment-content">
            <div class="heading-small padding-bottom">
              {{
                $translations.promos['packages'].generic[
                  'available-specialists'
                ]
              }}
            </div>

            <div
              class="cursor-pointer"
              @click="handleNavigateToAvailableSpecialist"
            >
              <div class="specialists-result margin-top-xs margin-bottom">
                <div class="image-container">
                  <img
                    v-for="(item, index) in specialistImages"
                    class="rounded-image"
                    :key="index"
                    :class="{ 'negative-margin': index > 0 }"
                    :src="item"
                    alt=""
                  />
                </div>
                <div class="icon icon-specialists-off" />
                <div class="text body" v-text="specialistResult" />
              </div>
            </div>

            <div class="body" v-text="availableSpecialists" />
          </div>
        </div>

        <!-- package cards list -->
        <div class="page-segment">
          <div class="page-segment-content">
            <div class="heading-small padding-bottom">
              {{ $translations.promos['packages'].title }}
            </div>

            <GenericPackagesList
              :packages="packages"
              :service-name="serviceName"
              :showCardButton="false"
              :showTitle="false"
              :can-open-package-modal="false"
            />
          </div>
        </div>

        <!-- FAQS -->
        <div class="segment">
          <div class="segment-content">
            <div class="small-tag padding-bottom-sm">
              {{ $translations.promos['packages'].generic.faq }}
            </div>
            <Accordion
              v-for="(question, key) in faq"
              :key="key"
              :text="question.title[lang]"
              :hasCustomContent="true"
              :is-collapsed="currentFaqIndex !== key"
              :custom-controller="true"
              @collapse="changeCurrentFaqIndex($event, key)"
            >
              <template v-slot:accordion>
                <div v-html="question.description[lang]"></div>
              </template>
            </Accordion>
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  Settings,
  Accordion,
  SliderNav,
  Promos
} from '@seliaco/red-panda'
import SkeletonDefault from '@/components/skeletons/SkeletonDefault'
import GenericPackagesList from '@/views/promos/packages/components/GenericPackagesList'

export default {
  name: 'GenericPackages',
  components: {
    PageContainer,
    GenericPackagesList,
    SliderNav,
    Accordion,
    SkeletonDefault
  },
  data () {
    return {
      specialists: {},
      packages: [],
      serviceName: null,
      serviceData: null,
      loading: false,
      faq: {},
      faqLoading: false,
      currentFaqIndex: null,
      lang: '',
      sliderOptions: [],
      specialistImages: [],
      actions: {
        back: {
          callback: () => {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.go(-1)
            }
          }
        }
      }
    }
  },
  mounted () {
    this.lang = this.$store.getters.language.lang
    this.getData()
  },
  methods: {
    setQueryTab (tab) {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            tab: String(tab)
          }
        })
        .catch(() => {})
    },
    async getData () {
      this.loading = true

      await Promos.Packages.getPackageServiceType(this.$route.params.id)
        .then((response) => {
          this.serviceData = {
            ...response,
            name: response[`name_${this.lang}`],
            name_plural: response[`name_plural_${this.lang}`]
          }
        })
        .then(() => {
          this.getFAQ()
        })

      const tab = this.serviceData?.prices[1] || this.serviceData?.prices[0]

      this.serviceName = this.serviceData.name

      this.setQueryTab(tab.price)
      this.setData(tab.price)

      this.serviceData &&
        this.serviceData.prices.map((option) => {
          if (option.packages.length <= 0) {
            return
          }
          this.sliderOptions = [
            ...this.sliderOptions,
            {
              icon: {
                on: 'icon-money-on',
                off: 'icon-money-off'
              },
              text: (option.price / 100).toLocaleString(),
              name: String(option.price),
              action: () => {
                this.$router.replace({
                  query: {
                    ...this.$route.query,
                    tab: String(option.price)
                  }
                })
              }
            }
          ]
        })

      this.loading = false

      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    },
    setData (price) {
      const data = this.serviceData?.prices.find(
        (option) => String(option.price) === String(price)
      )
      this.packages = data.packages.sort((a, b) =>
        String(a.max_uses).localeCompare(String(b.max_uses))
      )
      this.specialists = data.specialists
      const previewImages = this.specialists.map(
        (specialist) => specialist.user.profile_picture
      )
      this.specialistImages = []
      this.avatar(previewImages)
    },
    getFAQ () {
      this.faqLoading = true
      Settings.read({
        columns: 'value',
        eq: {
          key: 'FAQ_GENERIC_PACKAGES'
        }
      })
        .then((response) => {
          if (response.data[0].value) {
            this.faq = JSON.parse(response.data[0].value)
          }
        })
        .finally(() => {
          this.faqLoading = false
        })
    },
    changeCurrentFaqIndex (value, key) {
      this.currentFaqIndex = value ? key : null
    },
    avatar (images) {
      images.map((image) => {
        if (this.specialistImages.length === 3) {
          return
        }
        this.specialistImages = [
          ...this.specialistImages,
          image || require('@/assets/images/avatar.png')
        ]
      })
    },
    handleNavigateToAvailableSpecialist () {
      this.$router.push({
        name: 'PackageSpecialists',
        params: {
          packageId: this.packages[0].id
        },
        query: {
          preserveBackRoute: true
        }
      })
    }
  },
  computed: {
    specialistResult () {
      return `${this.specialists?.length} ${this.$translations.promos.packages.generic.specialist}`
    },
    availableSpecialists () {
      let text =
        this.$translations.promos.packages.generic[
          'available-specialists-description'
        ]

      text = text
        .replaceAll('{service_type}', this.serviceData?.name)
        .replaceAll(
          '{service_value}',
          Number(this.$route.query.tab / 100).toLocaleString()
        )

      return text
    },
    pageTitle () {
      return `${this.$translations.promos.packages.title} ${
        this.serviceData?.name || ''
      }`
    },
    packageDescription () {
      let text =
        this.$translations.promos.packages.generic['therapy-description']
      let name = this.serviceData ? this.serviceData.name_plural : ''
      name = name.replace(' ', '<br>')
      text = text.replace('{plural_service_name}', name)

      return text
    }
  },
  watch: {
    $route: {
      handler (route) {
        if (route.query && route.query.tab) {
          this.setData(route.query.tab)
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.box-shadow
  box-shadow: var(--shadow-gray)

.specialists-result
  display: grid
  grid-template-columns: 65px min-content max-content
  gap: 6px
  .text
    align-self: center
  .icon
    align-self: center
    font-size: var(--xl-3)
    color: var(--gray-50)
  .image-container
    display: flex
    .rounded-image
      border: 2px solid var(--white)
      flex: none
      object-position: center
      object-fit: cover
      height: 32px
      width: 32px
      border-radius: 9999px
    .negative-margin
      margin-left: -15px
</style>
