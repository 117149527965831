<template>
  <div v-if="packages">
    <!-- Listing Cards -->
    <div class="margin-bottom-sm" v-for="(packageData, i) in packages" :key="i">
      <div v-if="packages.length >= 1">
        <PackageCard
          v-selectable-container="{ elementLevel: 1 }"
          :serviceName="serviceName"
          :packageData="packageData"
          :currency="packageData.service_price.currency"
          :icon-name="packageData.service_icon_name"
          :show-button="showCardButton"
          :button-text="$translations.promos['packages'].view"
          :can-open-detail="{ modal: false, view: true }"
          :show-addi="true"
          :is-clickable="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PackageCard from './PackageCard'
import { selectableContainer } from '@seliaco/red-panda'

export default {
  name: 'GenericPackageList',
  components: { PackageCard },
  directives: {
    selectableContainer
  },
  props: {
    packages: Array,
    serviceName: String,
    showCardButton: {
      type: Boolean,
      default: true
    },
    showTitle: Boolean
  }
}
</script>
