<template>
  <PageContainer
    :title="$translations.promos['packages'].title"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="segment" v-if="loading">
        <div class="segment-content">
          <SkeletonDefault />
        </div>
      </div>

      <div v-if="!loading" class="page-segment height-full">
        <div class="page-segment-content">
          <div class="service-container margin-top">
            <!-- title -->
            <div
              class="service-title heading-mate-60"
              v-html="$translations.promos['packages'].generic['service-type']"
            ></div>

            <!-- services list -->
            <div class="service-list">
              <div class="heading-small margin-bottom">
                {{ $translations.promos['packages'].generic['package-of'] }}
              </div>

              <ServiceCard
                :options-data="optionsData"
                :action="cardActions"
                @changed="setService($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Promos } from '@seliaco/red-panda'
import SkeletonDefault from '@/components/skeletons/SkeletonDefault'
import ServiceCard from '@/components/cards/ServiceCard'
import { GenericPackagesEvent, PackageEvent } from '@/types/events'

export default {
  name: 'ServicePackages',
  components: {
    ServiceCard,
    PageContainer,
    SkeletonDefault
  },
  data () {
    return {
      loading: false,
      serviceData: null,
      optionsData: [],
      actions: {
        back: {
          callback: () => {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.go(-1)
            }
          }
        }
      },
      cardActions: {
        icon: 'icon-arrow-big-outline-right',
        callback: () => {
          this.$segment.track(GenericPackagesEvent.select_viewgenericpackages)
          this.$router.push({
            name: 'GenericPackages',
            params: {
              id: this.serviceData.id
            },
            query: {
              back: this.$route.fullPath
            }
          })
        },
        availableActive: false
      }
    }
  },
  mounted () {
    this.getServices()
  },
  methods: {
    getServices () {
      this.loading = true

      const lang = this.$store.getters.language.lang

      Promos.Packages.listByServiceType()
        .then((response) => {
          this.optionsData = response
          this.optionsData = this.optionsData.map((option) => {
            return {
              ...option,
              name: option[`name_${lang}`],
              description: option[`name_plural_${lang}`]
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    setService (option) {
      this.serviceData = option
      PackageEvent.packagesTypeClick({
        user: this.$store.getters['auth/user'],
        detailsPackage: {
          title: option.name,
          ...option
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.height-full
  height: 100%

.service-container
  display: grid
  grid-template-rows: 60px 1fr 60px
  height: 100%
  .service-title
    align-self: center
  .service-list
    align-self: center
</style>
