<template>
  <div class="service-card-wrapper" v-if="optionsData">
    <div
      v-selectable-container
      @click="onOptionSelected(option)"
      class="service-card"
      v-for="(option, index) in showAllServices(optionsData)"
      :key="index"
      :class="isCardActive(option)"
    >
      <!-- left side component -->
      <div class="left-side" :style="setBackground(option)"/>

      <!-- service info -->
      <div class="service-info-container" :style="{ width: 'calc(100% - 36px)' }">
        <!-- name -->
        <div class="service-name">
          {{ option.service ? option.service.name : option.name }}
        </div>

        <!-- description -->
        <div class="service-description">
          {{ option.service ? option.service.description : option.description }}
        </div>

        <!-- when have benefit or discount -->
        <div class="service-benefit-container" v-if="option.benefit || option.selia_promo">
          <div>
            <Chip
              :size="'small'"
              :color="option.benefit ? 'blue' : 'purple'"
              :text="chipText(option)"
            />
          </div>

          <div class="service-price">
            <template v-if="option.benefit">
              {{ (option.benefit.benefit.service.price / 100) | currency }}
            </template>

            <template v-else>
              {{ (option.selia_promo.price / 100) | currency }}
            </template>
          </div>
        </div>

        <!-- price and duration -->
        <div v-if="action.availableActive" class="service-price-and-duration">
          <!-- duration -->
          <div class="chip" :class="isChipActive(option)">
            <div v-bind:class="isIconActive(option)" />
            <div class="text">{{ option.service.duration }} min.</div>
          </div>

          <!-- price -->
          <div class="chip" :class="isChipActive(option)">
            <!-- has package -->
            <template v-if="option.user_package">
              <UsagesCoins
                :option="option"
                :max="option.user_package.package.max_uses"
                :remaining="option.user_package.remaining_uses"
                :icon-name="option.service.icon_name"
              />
            </template>

            <template v-else-if="option.benefit || option.selia_promo">
              <div :class="isIconActive(option)" />

              <div class="text">
                <template v-if="option.benefit">
                  <template v-if="option.benefit.benefit.percentage_value === 0">
                    {{ $translations.general['free-session'] }}
                  </template>

                  <template v-else>
                    {{ (option.benefit.benefit.service.total / 100) | currency }}
                  </template>
                </template>

                <template v-else>
                  {{ (option.selia_promo.total / 100) | currency }}
                </template>
              </div>
            </template>

            <!-- default -->
            <template v-else>
              <div :class="isIconActive(option)" />
              <div class="text">
                {{ (option.service.price / 100) | currency }}
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- selection icon -->
      <div
        @click="onOptionSelected(option)"
        :class="`${icon} ${isChipActive(option)}`"
      />
    </div>
  </div>
</template>

<script>
import UsagesCoins from '@/components/UsagesCoins'
import {
  Chip,
  selectableContainer,
  CurrencyFilter as currency,
  ServiceIcons
} from '@seliaco/red-panda'

export default {
  name: 'ServiceCard',
  components: {
    UsagesCoins,
    Chip
  },
  directives: {
    selectableContainer
  },
  props: {
    optionsData: null,
    action: {
      icon: String,
      callback: Function,
      availableActive: Boolean
    },
    itemDefault: Object
  },
  data () {
    return {
      valueData: null,
      styleConfig: ServiceIcons
    }
  },
  mounted () {
    if (this?.itemDefault) {
      this.valueData = this.itemDefault
    }
  },
  methods: {
    onOptionSelected (option) {
      if (option === this.valueData) {
        return
      }

      this.valueData = option
      this.$emit('changed', option)

      if (this.action.callback) {
        this.action.callback()
      }
    },
    isActive (option) {
      if (this.action.availableActive) {
        if (!this.valueData) {
          return false
        }

        return option.id === this.valueData.id
      }
    },
    showAllServices (value) {
      if (this.$route.query.service_id) {
        const service = []
        value.map((option) => {
          if (option.service.id === this.$route.query.service_id) {
            this.onOptionSelected(option)
            service.push(option)
          }
        })
        return service
      }
      return value
    },
    chipText (service) {
      if (service.selia_promo) {
        return service.selia_promo.name
      } else if (service.benefit) {
        const value = service.benefit.benefit.percentage_value

        if (value > 0) {
          return `${value}% ${this.$translations.promos.discount}`
        } else {
          return this.$translations.general['free-session']
        }
      }
    }
  },
  computed: {
    setBackground () {
      return (option) => {
        const icon = option?.service
          ? option.service.icon_name
          : option.icon_name

        const background = this.styleConfig[icon].background

        return `background: ${background}`
      }
    },
    icon () {
      return `selection-icon ${this.action.icon}`
    },
    isCardActive () {
      return (option) => {
        if (!this.isActive(option)) {
          return 'active'
        } else {
          return 'inactive'
        }
      }
    },
    isChipActive () {
      return (option) => {
        if (this.isActive(option)) {
          return 'active'
        } else {
          return 'inactive'
        }
      }
    },
    isIconActive () {
      return (option) => {
        if (this.isActive(option)) {
          return 'icon icon-money-off active'
        } else {
          return 'icon icon-money-off inactive'
        }
      }
    }
  },
  filters: {
    currency
  }
}
</script>

<style lang="sass" scoped>
.service-card-wrapper
  display: flex
  flex-direction: column
  gap: 8px

.service-card
  display: flex
  position: relative
  overflow: hidden
  cursor: pointer
  padding: 16px 16px 16px 32px
  border-radius: 16px
  gap: 16px
  transition-property: color, background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color
  transition-timing-function: cubic-bezier(.4,0,.2,1)
  transition-duration: .3s

  &.active
    background: var(--color-white)
    box-shadow: 0 5px 8px rgba(141,145,160,.1)
    border: 2px solid var(--color-gray-10)

  &.disable
    background: var(--gray-5)
    pointer-events: none

.left-side
  width: 16px
  position: absolute
  height: 100%
  left: 0
  top: 0

.service-info-container
  flex-grow: 1

  .service-name
    font-size: var(--tiny)
    font-weight: 600

  .service-description
    font-size: var(--sm)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    color: var(--color-gray-70)

  .service-benefit-container
    display: flex
    gap: 16px
    margin-top: 8px

    .service-price
      text-align: right
      padding: 2px 10px
      color: var(--color-gray-55)
      font-size: var(--xs)
      text-decoration: line-through
      white-space: nowrap
      align-self: end

  .service-price-and-duration
    display: flex
    gap: 8px
    margin-top: 8px

    .text
      align-self: center
      font-size: var(--tiny)

.icon
  align-self: center
  font-size: var(--xl)
  &.active
    color: var(--color-purple-10)
  &.inactive
    color: var(--color-gray-55)

.selection-icon
  flex: none
  align-self: center
  cursor: pointer
</style>
